.counters-container {
    padding: 0px 5px;

}

.counters-container .counter-container {
    margin-top: 0px;
}

.counters-container .counter-container .MuiGrid-item {
    padding: 8px;
}