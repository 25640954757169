.movies-container h2 {
    margin: 0;
    padding: 2px 0px;
}

.movies-table-container {
    margin: 5px 0px;
}

.pegination-container .MuiPagination-ul {
    justify-content: center;
    padding: 5px 0px;
}

.movies-container .genres-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.movies-container .MuiTable-root {
    table-layout: fixed
}